import React from "react";
import "./HistoriaEmpresa.css";

const HistoriaEmpresa = () => {
  const eventos = [
    {
      year: "2008",
      title: "titulo",
      description: "texto.",
      icon: "📱",
      color: "#3b4c7e", // Azul oscuro
    },
    {
      year: "2008",
      title: "titulo",
      description: "texto.",
      icon: "📱",
      color: "#3b4c7e", // Azul oscuro
    },
    {
      year: "2008",
      title: "titulo",
      description: "texto.",
      icon: "📱",
      color: "#3b4c7e", // Azul oscuro
    },
    {
      year: "2008",
      title: "titulo",
      description: "texto.",
      icon: "📱",
      color: "#3b4c7e", // Azul oscuro
    },
    {
      year: "2024",
      title: "Actualidad",
      description: "texto.",
      icon: "📱",
      color: "#3b4c7e", // Azul oscuro
    },
  ];

  return (
    <div className="historia-container">
      <h6 className="historia-title">Nuestra Historia</h6>
      <p className="historia-subtitle">
        Aquí va una breve descripcion o algo
      </p>

      <div className="timeline">
        {eventos.map((event, index) => (
          <div key={index} className="timeline-event">
            <div
              className="timeline-icon"
              style={{ backgroundColor: event.color }}
            >
              {event.icon}
            </div>
            <div className="timeline-details">
              <div className="timeline-year" style={{ color: event.color }}>
                {event.year}
              </div>
              <div className="timeline-title">{event.title}</div>
              <div className="timeline-description">{event.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoriaEmpresa;
